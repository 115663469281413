<script setup>
import { ref } from 'vue'
import UIkit from 'uikit'

import vectorPatternLight from '@/web/img/brand/vector-pattern-light.png'

const modal = ref(null)

const openModal = () => {
  UIkit.modal(modal.value).show()
}

defineExpose({
  openModal,
})
</script>

<template>
  <div id="ty" ref="modal" data-uk-modal>
    <div
      class="uk-modal-dialog uk-modal-body uk-background-cover uk-text-center uk-padding vb-rounded"
      :style="`background-image: url(${vectorPatternLight}); max-width: 420px !important;`"
    >
      <h2 class="uk-text-bold uk-h3">Thank you!</h2>
      <p class="uk-text-bold">We’ve received your information and will get back to you as soon as possible.</p>
      <div>
        <button
          class="uk-button uk-button-default uk-text-bold uk-modal-close"
          style="border-radius: 48px; background-color: white !important; margin: 0 auto"
          type="button"
        >
          Continue
        </button>
      </div>
      <button class="uk-modal-close-default" type="button" uk-close></button>
    </div>
  </div>
</template>
