<template>
  <div>
    <form :class="classes" class="uk-position-relative" @submit.prevent>
      <slot />
      <div class="uk-margin uk-flex uk-flex-center">
        <button class="uk-button uk-button-secondary uk-text-bold" style="border-radius: 48px" @click="submitData">
          {{ submitText }}
        </button>
      </div>
      <template v-if="loading">
        <!--LOADING OVERLAY-->
        <div class="uk-overlay-default vb-overlay-cart uk-position-cover" />
        <div class="uk-position-center vb-spinner-cart-update" data-uk-spinner />
        <!--end of LOADING OVERLAY-->
      </template>
    </form>

    <thank-you-modal ref="modal" />
  </div>
</template>

<script>
import UIkit from 'uikit'

import ThankYouModal from './TankYouModal.vue'

export default {
  name: 'ContactFormBase',
  components: {
    ThankYouModal,
  },
  props: {
    route: {
      type: String,
      required: true,
    },
    csrf: {
      type: String,
      required: true,
    },
    formData: {
      type: Object,
      default: () => {},
    },
    formId: {
      type: String,
      default: 'contact_t2',
    },
    submitText: {
      type: String,
      default: 'Submit',
    },
    classes: {
      type: String,
      default: 'uk-margin-medium-top',
    },
  },
  emits: ['reset'],
  data() {
    return {
      loading: false,
    }
  },
  methods: {
    submitData() {
      const error = Object.keys(this.formData).some(k => !this.formData[k])

      if (!error) {
        this.loading = true

        if (this.$gtm.enabled()) {
          window.dataLayer.push({
            event: 'contactFormSubmit',
          })
        }

        this.getReCaptchaToken()
          .then(token => {
            const postData = {
              ...this.formData,
              form_id: this.formId,
              'g-recaptcha-response': token,
              _token: this.csrf,
            }

            this.axios
              .post(this.route, postData)
              .then(({ data }) => {
                if (data.success) {
                  this.$emit('reset')
                  this.$refs.modal.openModal()
                } else {
                  UIkit.notification(data.message, { status: 'danger' })
                }
              })
              .catch(() => {
                UIkit.notification('Something went wrong.', { status: 'danger' })
              })
          })
          .catch(() => {
            UIkit.notification('Something went wrong.', { status: 'danger' })
          })
          .finally(() => {
            this.loading = false
          })
      }
    },
    getReCaptchaToken() {
      return new Promise((resolve, reject) => {
        grecaptcha.execute(import.meta.env.VITE_RECAPTCHA_SITE_KEY, { action: 'submit' }).then(
          token => {
            resolve(token)
          },
          error => {
            reject(error)
          }
        )
      })
    },
  },
}
</script>
